<template>
  <div class="declaration-detail wrapper" v-if="declaration">
    <a-spin :spinning="loading">
      <div class="detail-title">
        <div class="h1">{{ declaration.declaration_title }}</div>
        <div class="flex flex-center">
          <div class="park-name" v-if="declaration.park_name">
            {{ declaration.park_name }}
          </div>
          <div class="declaration-time">{{ declaration.declaration_time }}</div>
        </div>
      </div>
      <div
        class="declaration-content"
        v-html="declaration.declaration_content"
      ></div>
      <div
        v-if="declaration.declaration_attachment?.length > 0"
        style="padding-bottom: 50px"
      >
        <div class="line"></div>
        <attachment :data="declaration_attachment"></attachment>
      </div>
      <div class="btn" @click="sends">申报</div>
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
} from "@vue/runtime-core";
import { getDeclarationData } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { encode, decode } from "js-base64";
import Attachment from "@/components/attachment.vue";
import { fromType } from "vue-types";
export default defineComponent({
  name: "declarationDetail",
  components: {
    Attachment,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const pageData = reactive({
      declaration: null,
      declarationId: null,
      loading: true,
      declaration_attachment: [],
    });
    const sends = () => {
      url.navigateTo("/declaration/decform", {
        t: encode(`${pageData.declaration.declaration_id}`),
      });
    };
    const getDeclarationDataFun = (_declarationId) => {
      getDeclarationData({ declaration_id: _declarationId })
        .then((res) => {
          if (res.code == 200) {
            pageData.declaration = res.data.declaration;
            pageData.declaration_attachment =
              res.data.declaration.declaration_attachment;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };

    watch(
      () => route.query,
      (val) => {
        if (route.path == "/declaration/detail") {
          let query = decode(val?.t).split(",");
          if (query[0]) {
            pageData.loading = true;
            pageData.declarationId = query[0];
            getDeclarationDataFun(query[0]);
          } else {
            pageData.loading = false;
            pageData.declaration = null;
            message.error("暂无公告信息");
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    return {
      ...toRefs(pageData),
      sends
    };
  },
});
</script>
<style lang="less" scoped>
.btn {
  width: 400px;
  height: 60px;
  background-color: #1276cb;
  border-radius: 30px;
  line-height: 60px;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
  margin: 126px auto;
}
.declaration-detail {
  .detail-title {
    padding: 40px;
    border-bottom: 1px solid #dfdfdf;
    text-align: center;
    .h1 {
      font-size: 28px;
      color: #212531;
      margin-bottom: 20px;
    }
    .flex-center {
      justify-content: center;
    }
    .park-name {
      min-width: 80px;
      height: 22px;
      line-height: 22px;
      border-radius: 11px;
      border: solid 1px #1276cb;
      color: #1276cb;
      font-size: 14px;
      text-align: center;
      margin-right: 20px;
    }
    .declaration-time {
      color: #212531;
      opacity: 0.7;
      font-size: 16px;
    }
  }
  .declaration-content {
    padding: 40px 0;
  }
  .line {
    height: 20px;
    background-color: #f3f5f8;
  }
}
</style>
